import Vue from 'vue'
import VueMeta from 'vue-meta'
import App from './App.vue'
import router from "./routes";
import vuetify from './plugins/vuetify';
import Toasted from 'vue-toasted';
 
Vue.use(Toasted);
Vue.toasted.register('api_error', 'Error occurred contacting the server', {
    type : 'error',
    // icon : 'error_outline',
    position:'bottom-right',
    duration: 4000
})

Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
})

Vue.filter('truncate', function (text, length, suffix = '...') {
    if (text.length > length) {
        return text.substring(0, length) + suffix;
    }
    return text;
});

// require('dotenv').config({ path: '..\\..\\..\\env\\env.env' });

Vue.config.productionTip = false

new Vue({
    vuetify,
    router,
    render: h => h(App)
}).$mount('#app')

// window.env = {
//     API_URL: process.env.VUE_APP_API_URL
// };
// console.log("main.js window.env.API_URL", window.env.API_URL);

